<template>
<div>
  
                        
      <div class="rounded-lg shadow border border-white bg-white">
            <div class="row justify-content-centre align-items-center mt-2 mb-3">   

                    <h4 class="col ml-3 font-weight-lighter var_color"> <span class="fa fa-hand-holding-usd"></span> Gestion Des Dépenses </h4> 



                    <div class="col mr-3">
                                    <b-button pill size="sm"  class="m-2 float-right" variant="outline-success" @click="add_click($event.target)">
                                                <i class="fa fa-plus-square"></i>
                                        <span> Ajouter</span> 
                                    </b-button>

                                    <b-button pill size="sm"  class="m-2 float-right" variant="outline-success" @click="categories_Click">
                                                <i class="fa fa-plus-square"></i>
                                        <span> Catégories</span> 
                                    </b-button>

                                    <b-button @click="exportDox" pill size="sm"  class="m-2 float-right" variant="info">
                                        <i class="fa fa-print"></i>
                                        <span> Imprimer</span> 
                                    </b-button>

                    </div>
            </div>
    <div class="input-group  border border-white rounded bg-white p-3  justify-content-centre align-items-center text-info">

                <label for="dateDebut" class="mr-sm-2  pt-2 pl-1"> De :</label>
                    <b-form-datepicker
                    class="mr-sm-2 border rounded"
                    id="datepicker-dateDebut"
                    placeholder="date fin"
                    v-model="itemFormSerch.dateDebut"
                    :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                    locale="fr" />
        
      

                       
                <label for="dateFin" class="mr-sm-2  pt-2 pl-1"> Au :</label>
                    <b-form-datepicker
                    class="mr-sm-2 border rounded"
                    id="datepicker-dateFin"
                    placeholder="date fin"
                    v-model="itemFormSerch.dateFin"
                    :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                    locale="fr" />

                                


            <div class="col">
                                <b-input-group prepend="Catégorie" class="mt-0">

                                        <select class="form-control" id="currentTypeSerch" v-model="itemFormSerch.TypeSerch">
                                                <option v-for="item in types" :key="item.id" v-bind:value="item.id">   
                                                {{ item.nom }}
                                                </option>

                                                <option value=0>Tous</option>

                                        </select>


                                    <b-input-group-append>
                                    <b-button variant="info"  @click="fetchList">
                                        <span class="fa fa-search"></span>
                                        
                                        </b-button>
                                    </b-input-group-append>
                                </b-input-group>

            </div>
    </div>

        <b-table 
        sort-icon-left
        head-variant="light"
        responsive 

        :fields="fields" 
        :items="itemslist"
        :per-page="perPage"
        :current-page="currentPage"

        id="main-table"
        >
            <template #cell(actions)="row">
                    <b-button pill size="sm"  class="mr-2 mt-1 mb-1 float-right" variant="outline-danger" @click="del_click(row.item)">
                                <i class="fa fa-trash-alt"></i>
                        
                    </b-button>
                    <b-button pill size="sm" class="mr-2 mt-1 mb-1 float-right" variant="outline-primary" @click="edit_click(row.item, row.index, $event.target)">
                            <i class="fa fa-pencil-alt"></i>
                    
                    </b-button>
                </template>
        
        </b-table>

        
                <b-pagination
                pills
                v-model="currentPage"
                :total-rows="rows_pagin"
                :per-page="perPage"
                aria-controls="main-table"
                align="center"
                ></b-pagination>
      </div>

              <div class="card border-left-success shadow mt-3">
                <div class="card-body p-0">

                        <div class=" d-flex justify-content-center pt-1 text-secondary">
                                <h4 class="font-weight-normal p-2">TOTAL : {{total_compt}} DH</h4>
                        </div>

                </div>
              </div>


    <!-- le modal -->
    <b-modal id="banqModal" title="Dépense" size="lg"  hide-footer>
        <form @submit.prevent="handleSubmit">
                <div class="row">
                            <div class="form-group col">
                                            <label for="depense_type_id">Type de la dépense :</label>
                                            <select class="form-control" id="depense_type_id" v-model="itemForm.depense_type_id"
                                            :class="{ 'is-invalid': isSubmitted && $v.itemForm.depense_type_id.$error }">
                                            <option v-for="item in types" :key="item.id" v-bind:value="item.id">   
                                                {{ item.nom }}
                                            </option>
                                            </select>

                                <div v-if="isSubmitted && !$v.itemForm.depense_type_id.required" class="invalid-feedback">Ce champ est obligatoire !!</div>

                            </div>



                            <div class="form-group col">
                                <label for="date_exp" class="mr-sm-2">Date :</label>
                                    <b-form-datepicker
                                    class="mr-sm-2"
                                    id="datepicker-date_exp"
                                    placeholder="date"
                                    v-model="itemForm.date"
                                    :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                                    locale="fr" />
                            </div>
                    </div>

                            <div class="form-group">
                                <label for="montant">Montant :</label>
                                <input type="text" v-model="itemForm.montant" id="montant" name="montant" class="form-control"
                                    :class="{ 'is-invalid': isSubmitted && $v.itemForm.montant.$error }" />
                                <div v-if="isSubmitted && !$v.itemForm.montant.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                                <div v-if="isSubmitted && !$v.itemForm.montant.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
                            </div>


                <div class="row">
                            <div class="form-group col">
                                            <label for="mode">Mode de paiement :</label>
                                            <select class="form-control" id="mode" v-model="itemForm.mode">
                                            <option v-for="item in modes" :key="item.id" v-bind:value="item.mode">   
                                                {{ item.mode }}
                                            </option>
                                            </select>

                            </div>
                            <div class="form-group col">
                                <label for="infos">Infos de paiement :</label>
                                <input type="text" v-model="itemForm.infos" id="infos" name="infos" class="form-control" />

                            </div>
                </div>
            <div  v-if="d_is_tresor==true" class="form-group">
                            <label for="bank_compte_id">Compte :</label>
                            <select class="form-control" id="bank_compte_id" v-model="itemForm.bank_compte_id"
                            :class="{ 'is-invalid': isSubmitted && $v.itemForm.bank_compte_id.$error }">
                            <option v-for="item in comptes" :key="item.id" v-bind:value="item.id">   
                                {{ item.nom }}
                            </option>
                            </select>

                <div v-if="isSubmitted && !$v.itemForm.bank_compte_id.required" class="invalid-feedback">Ce champ est obligatoire !!</div>

            </div>


            <div class="form-group">
            <label for="Description">Description</label>
            <textarea name="description" id="description"  rows="2" class="form-control" v-model="itemForm.description"></textarea>

            </div>

            <div class="form-group">
                <button type="submit" class="btn  btn-primary btn-block">{{ModalType}}</button>
            </div>




        </form>
    </b-modal>

    <!-- le modal -->
    <b-modal id="comptModal" title="Sélectionnez un compte"  hide-footer>
            <form @submit.prevent="handleSubmitCmp">
                            <div class="form-group">
                            <label for="bank_compte_id">Compte :</label>
                            <select class="form-control" id="bank_compte_id" v-model="itemForm.bank_compte_id">
                            <option v-for="item in comptes" :key="item.id" v-bind:value="item.id">   
                                {{ item.nom }}
                            </option>
                            </select>

            </div>


            <div class="form-group">
                <button type="submit" class="btn  btn-primary btn-block">Valider</button>
            </div>
            </form>  

    </b-modal>





 <b-modal  id="catmodal" title="Catégories"  hide-footer>

            <form @submit.prevent="handleSubmitCatg">

                            <div class="form-group">
                                <label for="currentType">Catégorie :</label>
                                <input type="text" v-model="currentType" id="currentType" name="currentType" class="form-control" />

                            </div>





            <div class="form-group">
                <button type="submit" class="btn  btn-primary btn-block">Ajouter</button>
            </div>

        </form>



        <b-table 
        sort-icon-left
        small 
        sticky-header
        :fields="fieldsCat" 
        :items="types"
        >
            <template #cell(actions)="row">
                    <b-button pill size="sm"  class="mr-2 float-right" variant="outline-danger" @click="del_categclick(row.item)">
                                <i class="fa fa-trash-alt"></i>
                        
                    </b-button>
            </template>
        
        </b-table>


</b-modal>
    <!-- les trucs public -->

    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>


</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required,decimal} from "vuelidate/lib/validators";
import { HeadingLevel,PageNumber, PageNumberFormat,Footer, Header, Table,TableCell,TableRow,Document, Paragraph,WidthType,VerticalAlign,AlignmentType, Packer, TextRun } from "docx";
import { saveAs } from 'file-saver';

export default {
      computed: {
        d_is_tresor() {
      return this.$store.getters.get_is_tresor;
    },
    entet_pag() {

      if(this.$store.getters.get_entete)
      {
            return this.$store.getters.get_entete;
      }
      else
      {
            return '';
      }
    },
    sousentet_pag() {

      if(this.$store.getters.get_sousentete)
      {
            return this.$store.getters.get_sousentete;
      }
      else
      {
            return '';
      }
    },
    pied_pag() {

      if(this.$store.getters.get_pied)
      {
            return this.$store.getters.get_pied;
      }
      else
      {
            return '';
      }
    },
    rows_pagin() {
        return this.itemslist.length
      },
    user_name() {

      if(this.$store.getters.get_user)
      {
            return this.$store.getters.get_user.name;
      }
      else
      {
            return '';
      }
    },

      total_compt: function() {
      return this.itemslist.reduce((a, b) => +a + +b.montant, 0);
    },
      },
    mounted(){
        this.fetchList()
        this.fetchFormModeComptesTypes()
    },
	data () {
		return {
            perPage: 10,
            currentPage: 1,
            itemFormSerch:{
                TypeSerch:0,
                dateDebut:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                dateFin:new Date().toJSON().slice(0,10).replace(/-/g,'-')
            },
            itemslist: [],
            modes:[],
            comptes:[],
            types:[],
            currentType:'',
            itemForm: {
                    id:'',
                    date:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    montant:'',
                    infos:'',
                    mode:'',
                    description:'',
                    user:'user',
                    depense_type_id:'',
                    bank_compte_id:''},
            fields: [
                {key: 'date', label: 'Date', sortable: true},
                {key: 'montant', label: 'Montant', sortable: true},
                {key: 'infos', label: 'Infos', sortable: true},
                {key: 'mode', label: 'Mode', sortable: true},
                {key: 'description', label: 'Description', sortable: true},
                {key: 'user', label: 'Utilistateur', sortable: true},
                { key: 'actions', label: '' }
            ],
            fieldsCat: [
                {key: 'nom', label: 'Catégorie', sortable: true},
                { key: 'actions', label: '' }
            ],
            //public trucs
            ModalEdit: false,
            ModalType:'Ajouter',
            isSubmitted: false,
            isLoading: false
		}
	},
    validations: {
            itemForm: {
                date: {
                    required
                },
                montant: {
                    required,
                    decimal
                },
                depense_type_id: {
                    required
                },
                bank_compte_id: {
                    required
                }
            }
    },
    methods :{



        exportDox()
    {

                        let typ = ""


                        if(this.itemFormSerch.TypeSerch==0)
                        {
                        typ="Tous"
                        }
                        else
                        {
                        let mm=this.types.findIndex(item => item.id === this.itemFormSerch.TypeSerch)

                        typ= this.types[mm].nom
                        }



                        let datrw =[new TableRow({
                                    children: [
                                        new TableCell({
                                            width: {
                                                size: 2000,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({

                                                        
                                        children: [
                                            new TextRun({
                                                text: "Date",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                                            spacing: {
                                                                before: 100,
                                                                after: 100
                                                            },
                                                        heading: HeadingLevel.HEADING_3,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),


                                        new TableCell({
                                            width: {
                                                size: 3000,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        
                                        children: [
                                            new TextRun({
                                                text: "Montant",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                                        heading: HeadingLevel.HEADING_3,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),

                                        new TableCell({
                                            width: {
                                                size: 2000,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({

                                                        
                                        children: [
                                            new TextRun({
                                                text: "Infos",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                                        heading: HeadingLevel.HEADING_3,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),

                                        new TableCell({
                                            width: {
                                                size: 2000,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({

                                                        
                                        children: [
                                            new TextRun({
                                                text: "Mode",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                                        heading: HeadingLevel.HEADING_3,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),

                                        new TableCell({
                                            width: {
                                                size: 3000,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({

                                                        
                                        children: [
                                            new TextRun({
                                                text: "Description",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                                        heading: HeadingLevel.HEADING_3,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),
                                        new TableCell({
                                            width: {
                                                size: 3000,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({

                                                        
                                        children: [
                                            new TextRun({
                                                text: "Utilisateur",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                                        heading: HeadingLevel.HEADING_3,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),


                                    ],
                                })]

                        this.itemslist.forEach(function(item){

                        let r = new TableRow({


                                    children: [
                                        new TableCell({
                                            width: {
                                                size: 2000,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                
                                                        children: [
                                        new TextRun({
                                            text: item.date,
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                                        spacing: {
                                                        before: 50,
                                                        after: 50
                                                    },
                                                        heading: HeadingLevel.HEADING_3,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),


                                        new TableCell({
                                            width: {
                                                size: 3000,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                       
                                                        children: [
                                        new TextRun({
                                            text: item.montant.toString(),
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                                        heading: HeadingLevel.HEADING_3,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),

                                        new TableCell({
                                            width: {
                                                size: 2000,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                       
                                                        children: [
                                        new TextRun({
                                            text: item.infos,
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                                        heading: HeadingLevel.HEADING_3,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),

                                                                                new TableCell({
                                            width: {
                                                size: 2000,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        children: [
                                        new TextRun({
                                            text: item.mode,
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                                        heading: HeadingLevel.HEADING_3,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),

                                                                                new TableCell({
                                            width: {
                                                size: 3000,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        children: [
                                        new TextRun({
                                            text: item.description,
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                                        heading: HeadingLevel.HEADING_3,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),


                                                                                new TableCell({
                                            width: {
                                                size: 3000,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({

                                                        children: [
                                        new TextRun({
                                            text: item.user,
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                                        heading: HeadingLevel.HEADING_3,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),

                                    ],
                                })

                        datrw.push(
                        r
                        )
                        });

                           

                        const table = new Table({

                            rows:  datrw
                        })

const doc = new Document({

    sections: [
        {

                                    properties: {
                                        page: {
                                            pageNumbers: {
                                                start: 1,
                                                formatType: PageNumberFormat.DECIMAL,
                                            },
                                            margin:{
                                left:500,
                                right:500
                            }
                                        },
                                    },
                    headers: {
                        default: new Header({
                            children: [
                        new Paragraph({
                                    children: [
                            new TextRun({
                                text: this.entet_pag,
                                color: '636572',
                                bold: true,

                                }),
                                    ],
                                    heading: HeadingLevel.TITLE,
                                    alignment: AlignmentType.CENTER,
                                }),

                        new Paragraph({
                                    children: [
                            new TextRun({
                                text: this.sousentet_pag,
                                color: '636572',
                                bold: false,
                                font: "Segoe UI"
                                })

                                    ],
                                border: {
                                bottom: {
                                        color: "636572",
                                        space: 4,
                                        value: "single",
                                        size: 10,
                                    }},
                                    heading: HeadingLevel.HEADING_1,
                                    alignment: AlignmentType.CENTER,
                                }),




                            ],
                        }),
                    },
                    footers: {
                        default: new Footer({
                            children: [

                        new Paragraph({
                                    children: [
                            new TextRun({
                                text: this.pied_pag,
                                color: '636572',
                                bold: false,
                                })

                                    ],
                                border: {
                                top: {
                                        color: "636572",
                                        space: 4,
                                        value: "single",
                                        size: 10,
                                    }},
                                    heading: HeadingLevel.HEADING_3,
                                    alignment: AlignmentType.CENTER,
                                }),

                                new Paragraph({
                                    alignment: AlignmentType.RIGHT,
                                    children: [
                                        new TextRun({
                                            children: ["Page : ", PageNumber.CURRENT],
                                            color: '636572'
                                        }),
                                        new TextRun({
                                            children: [" Sur ", PageNumber.TOTAL_PAGES],
                                            color: '636572'
                                        }),
                                    ],
                                }),
                            ],
                        }),
                    },



                                    children: [

                                        new Paragraph({



                                            children: [

                                                new TextRun({
                                                text: "Dépenses",
                                                color: '000000',
                                                bold: false,
                                                font: "Segoe UI"
                                            }),


                                            ],

                                            heading: HeadingLevel.HEADING_1,
                                                                alignment: AlignmentType.CENTER,

                                                spacing: {
                                                    after: 300,
                                                    before: 300
                                                },
                                        }),
                                        
                                        
                                        new Paragraph({



                                            children: [
                                                new TextRun({
                                                text: "De : "+this.itemFormSerch.dateDebut+" Au "+this.itemFormSerch.dateFin,
                                                color: '000000',
                                                bold: false,
                                                font: "Segoe UI"
                                            }),
                                                new TextRun({
                                                text: "      Type : "+typ+"         Total DH : "+this.total_compt,
                                                color: '000000',
                                                bold: false,
                                                font: "Segoe UI"
                                            })
                                            ],

                                            heading: HeadingLevel.HEADING_3,
                                            alignment: AlignmentType.CENTER,

                                                spacing: {
                                                    after: 300,
                                                    before: 300
                                                },
                                        }),



                                        table
                                        
                                        ]

        }]

});

                            Packer.toBlob(doc).then(blob => {

                        saveAs(blob, "Dépenses.docx");

                            });

    },



        fetchFormModeComptesTypes: function () {
                    this.isLoading=true;
                    this.$http.get('/bankModes')
                    .then((result) => {
                                this.modes = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
            //comptes :
                    this.isLoading=true;
                    this.$http.get('/bankComptes')
                    .then((result) => {
                                this.comptes = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });

            //Types :
                    this.isLoading=true;
                    this.$http.get('/depenseTypes')
                    .then((result) => {
                                this.types = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });       
        },

        itemslisttore(){
                        this.isLoading=true;

            this.$http.post('/depenses',{
            date:this.itemForm.date,
            montant:this.itemForm.montant,
            infos:this.itemForm.infos,
            mode:this.itemForm.mode,
            description:this.itemForm.description,
            depense_type_id:this.itemForm.depense_type_id,
            user:this.itemForm.user,
            bank_compte_id:this.itemForm.bank_compte_id

                })
                .then(response => {

                    this.isLoading=false;
                    this.itemslist.unshift(response.data.data);
                    this.$bvModal.hide('banqModal')

                    //afficher message
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données sauvegardées',
                    message: 'Les données sont sauvegardées correctement '
                })


                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Sauvegarde',
                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                })
                });

        },
        banqueEdit(){

            this.isLoading=true;

            this.$http.patch('/depenses/'+this.itemForm.id,{

            date:this.itemForm.date,
            montant:this.itemForm.montant,
            infos:this.itemForm.infos,
            mode:this.itemForm.mode,
            description:this.itemForm.description,
            depense_type_id:this.itemForm.depense_type_id,
            user:this.itemForm.user,
            bank_compte_id:this.itemForm.bank_compte_id

                })
                .then(response => {

                    this.isLoading=false;
                    console.log(response.data)
                    this.$bvModal.hide('banqModal')

                    let index = this.itemslist.findIndex(item => item.id === this.itemForm.id)
                    this.itemslist.splice(index, 1,response.data.data)
                    
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données modifiées',
                    message: 'Les données sont modifiées correctement'
                    })


                    })
                    .catch(error=> {console.log(error)
                    this.isLoading=false;


                    this.flashMessage.show({
                    status: 'error',
                    title: 'Erreur De Modification',
                    message: 'Impossible  de modifier les données .. Réessayez !!'
                })
                });

        },
        banqueDell(){
                //USER HANDLE
                this.itemForm.user= this.user_name

                
            this.isLoading=true;
            this.$http.get('/suppDepense/'+this.itemForm.id+'/'+this.itemForm.bank_compte_id+'/'+this.itemForm.date+'/'+this.itemForm.user)
                .then(response => {
                console.log(response.data)
                    this.isLoading=false;



                    
                this.flashMessage.show({
                status: 'success',
                title: 'Données Supprimées',
                message: 'Les données sont supprimées correctement'
                })


                    let index = this.itemslist.findIndex(item => item.id === this.itemForm.id)
                    this.itemslist.splice(index, 1)




                    })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de supprimer les données .. Réessayez !!'
                })
                });

        },
        add_click(button) {

        this.itemForm.id=''
        this.itemForm.date=new Date().toJSON().slice(0,10).replace(/-/g,'-'),
        this.itemForm.montant=''

        this.itemForm.infos=''
        this.itemForm.mode=''
        this.itemForm.description=''
        this.itemForm.depense_type_id=''


        this.ModalEdit=false;
        this.ModalType='Ajouter'

        this.$root.$emit('bv::show::modal', 'banqModal', button)
        },
        edit_click(item, index, button) {

                this.$confirm("Voulez-vous vraiment modifier cet élément ??")
                 .then(() => {


        this.itemForm.id=item.id
        this.itemForm.date=item.date
        this.itemForm.montant=item.montant

        this.itemForm.infos=item.infos
        this.itemForm.mode=item.mode
        this.itemForm.description=item.description
        this.itemForm.depense_type_id=item.depense_type_id




                this.ModalEdit=true;
                this.ModalType='Modifier'

                this.$root.$emit('bv::show::modal', 'banqModal', button)
                })
                .catch(error=> {
                console.log(error)

                });


        },
        del_click(item) {

                this.$confirm("Voulez-vous vraiment supprimer cet élément ??")
                 .then(() => {


        this.itemForm.id=item.id
        this.itemForm.date= new Date().toJSON().slice(0,10).replace(/-/g,'-')

        
        if(this.d_is_tresor==false)
            {

                    this.itemForm.bank_compte_id=-1;

                     this.banqueDell(); //appliquer supp

            }
            else
            {
                this.itemForm.bank_compte_id=''

                       this.$root.$emit('bv::show::modal', 'comptModal')
            }
        


        


                                })
                                .catch(error=> {
                                console.log(error)

                                    });




        },
        fetchList: function () {
                    this.isLoading=true;
                    this.$http.get('/depensePrTyp/'+this.itemFormSerch.TypeSerch+'/'+this.itemFormSerch.dateDebut+'/'+this.itemFormSerch.dateFin)
                    .then((result) => {
                                this.itemslist = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },
        handleSubmit() {

            
                //USER HANDLE
                this.itemForm.user= this.user_name

                if(this.d_is_tresor==false)
                    {
                        this.itemForm.bank_compte_id=0
                    }

                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {


                            if(this.ModalEdit)
                            {
                                this.banqueEdit()
                            }
                            else
                            {

                                this.itemslisttore()
                            }   

                }

        },
        handleSubmitCmp() {

            if(this.itemForm.bank_compte_id)
            {
                this.banqueDell()

                this.$bvModal.hide('comptModal')
            }
            else
            {
                this.flashMessage.show({
                status: 'info',
                title: 'Sélectionnez un compte !!',
                })

            }
        },
        categories_Click()
        {

        this.currentType=''
        this.$root.$emit('bv::show::modal', 'catmodal')

        },
        handleSubmitCatg() {

            if(this.currentType)
            {
                this.itemslisttoreType(this.currentType)

            }
            else
            {
                this.flashMessage.show({
                status: 'info',
                title: 'Entrez un type de dépense !!',
                })

            }
        },
        itemslisttoreType(nm){
                        this.isLoading=true;

            this.$http.post('/depenseTypes',{
            nom:nm

                })
                .then(response => {

                    this.isLoading=false;
                    this.types.unshift(response.data.data);

                    //afficher message
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données sauvegardées',
                    message: 'Les données sont sauvegardées correctement '
                })


                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Sauvegarde',
                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                })
                });

        },

        del_categclick(itm) {

                this.$confirm("Voulez-vous vraiment supprimer cet élément ?? Attention si cet élément est déjà utilisé ,vous NE pouvez PAS le supprimer")
                 .then(() => {


            this.isLoading=true;

            this.$http.delete('/depenseTypes/'+itm.id)
                .then(response => {
                console.log(response.data)
                this.isLoading=false;   
                this.flashMessage.show({
                status: 'success',
                title: 'Données Supprimées',
                message: 'Les données sont supprimées correctement'
                })


                    let index = this.types.findIndex(item => item.id === itm.id)


                   this.types.splice(index, 1)




                    })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de supprimer les données .. Réessayez !!'
                })
                });
                                })

                  //erreur de dialog              
                .catch(error=> {
                console.log(error)

                });




      },
    },
    components: {
              Loading      
    }
}
</script>

<style  scoped>
.var_color{
  color: #926e99;
}
</style>