<template>
  

<div>

 <b-modal  id="micromodal" title="Avance / Traites"  size="lg" hide-footer>


        <form @submit.prevent="handleSubmit">


            <div class="form-group">
                <label for="montant">Montant (DH) : {{montant}}</label>
            </div>


            <div class="row">

                            <div class="form-group col">
                            <div class="custom-control custom-switch">
                            <input type="checkbox"
                            class="custom-control-input" 
                            id="is_avance"
                                    v-model="itemForm.is_avance"
                                    name="is_avance"
                                    value=true
                                    unchecked-value=false>

                            <label class="custom-control-label" for="is_avance">Avance :</label>
                            </div>

                            <input v-if="itemForm.is_avance" type="text" v-model="itemForm.avance" id="infos" name="infos" class="mt-2 form-control"
                                        :class="{ 'is-invalid': isSubmitted && $v.itemForm.avance.$error }" />
                                        <div v-if="isSubmitted && !$v.itemForm.avance.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                                        <div v-if="isSubmitted && !$v.itemForm.avance.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
                            </div>

                        <div v-if="itemForm.is_avance  && d_is_tresor==true" class="form-group col">
                                        <label for="bank_compte_id">Compte :</label>
                                        <select class="form-control" id="bank_compte_id" v-model="itemForm.bank_compte_id"
                                        :class="{ 'is-invalid': isSubmitted && $v.itemForm.bank_compte_id.$error }">
                                        <option v-for="item in comptes" :key="item.id" v-bind:value="item.id">   
                                            {{ item.nom }}
                                        </option>
                                        </select>

                            <div v-if="isSubmitted && !$v.itemForm.bank_compte_id.required" class="invalid-feedback">Ce champ est obligatoire !!</div>

                        </div>

                     </div>





            <div class="row">

            <div class="form-group col">
                <label for="nmbr_mois">Nombre Des Mois :</label>
                <input type="text" v-model="itemForm.nmbr_mois" id="nmbr_mois" name="nmbr_mois" class="form-control"                
                :class="{ 'is-invalid': isSubmitted && $v.itemForm.nmbr_mois.$error }" />
                <div v-if="isSubmitted && !$v.itemForm.nmbr_mois.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                <div v-if="isSubmitted && !$v.itemForm.nmbr_mois.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
            </div>


            <div class="form-group col">
                <label for="date_first_paiem" class="mr-sm-2">Date Du Premier Paiement:</label>
                    <b-form-datepicker
                    class="mr-sm-2"
                    id="datepicker-date_first_paiem"
                    placeholder="Date Echéance"
                    v-model="itemForm.date_first_paiem"
                    :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                    locale="fr" />
            </div>

            </div>


            <div class="form-group">
            <label for="Description">Description :</label>
            <textarea name="description" id="description"  rows="2" class="form-control" v-model="desc"></textarea>

            </div>

            <div class="form-group">
                <button type="submit" class="btn  btn-primary btn-block">Valider</button>
            </div>

        </form>



</b-modal>

    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>
</div>

</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required,decimal} from "vuelidate/lib/validators";


export default {
 props: ['montant','client_id','comptes','desc'],

      computed: {
        d_is_tresor() {
      return this.$store.getters.get_is_tresor;
    },
    user_name() {

      if(this.$store.getters.get_user)
      {
            return this.$store.getters.get_user.name;
      }
      else
      {
            return '';
      }
    },
  },
	data () {
		return {
            
            itemForm: {
                    avance:0,
                    date_first_paiem:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    description:'',
                    is_avance:0,
                    nmbr_mois:1,
                    bank_compte_id:''},

            isSubmitted: false,
            isLoading: false,

            isLotGst: false
		}
	},
    validations: {
            itemForm: {
                avance: {
                    required,
                    decimal
                },
                date_first_paiem: {
                    required
                },
                is_avance: {
                    required
                },
                nmbr_mois: {
                    required,
                    decimal
                },
                bank_compte_id: {
                    required
                }
            }

    },
    methods :{

              handleSubmit() {

                if(this.itemForm.is_avance==0)
                {
                    this.itemForm.avance = 0
                    this.itemForm.bank_compte_id = 0
                }
                else if(this.d_is_tresor==false)
                {
                    this.itemForm.bank_compte_id = -1
                }

                this.isSubmitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {

                        if(this.itemForm.nmbr_mois>0)
                        {

                                    this.isLoading=true;

                                    this.$http.post('/storeMicroCredi',{
                                    montant:this.montant,
                                    is_avance:this.itemForm.is_avance,
                                    avance:this.itemForm.avance,
                                    date_first_paiem:this.itemForm.date_first_paiem,
                                    nmbr_mois:this.itemForm.nmbr_mois,
                                    bank_compte_id:this.itemForm.bank_compte_id,
                                    client_id:this.client_id,
                                    user:this.user_name,
                                    description:this.desc,


                                        })
                                        .then(response => {

                                            this.isLoading=false;
                                            console.log(response.data.data);

                                            //afficher message
                                            this.flashMessage.show({
                                            status: 'success',
                                            title: 'Données sauvegardées',
                                            message: 'Les données sont sauvegardées correctement '
                                            })

                                            this.$parent.click_RechrchFourns(0);

                                            this.$bvModal.hide('micromodal');
                                        })
                                        .catch(error=> {console.log(error)
                                        this.isLoading=false;


                                                        this.flashMessage.show({
                                        status: 'error',
                                        title: 'Erreur De Sauvegarde',
                                        message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                                        })
                                        });


                        }
                        else
                        {
                            this.itemForm.nmbr_mois = ''
                            this.isSubmitted = true;
                            this.$v.$touch();

                            this.flashMessage.show({
                            status: 'info',
                            title: 'Entrez un nombre des mois valide !!',
                            })
                        }



                }

            },

        
    }

    ,
    components: {
              Loading,
             
    }
}
</script>

<style>

</style>