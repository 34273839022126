<template>
  

<div  class="card rounded-lg shadow border border-white bg-white h-100 mb-0 pb-3 overflow-auto" >
    <div class="card-header bg-transparent">
             
           

<div class="row">


    <div class="col">
        
               <h5 class="ml-2 var_color font-weight-light"> <span class="fa fa-folder-open"></span>  Suivi Des Paiements Clients </h5>
        
    </div>



</div>


    </div>




    <div class="row card-body h-100 p-0 m-0">


    <div class="col-1">     
    </div>

                <b-collapse id="collap-categ" visible  class="col-5 h-100 mr-3 ml-0 mt-2 p-0">

                            <div class="card border border-light shadow h-100 overflow-auto">
                            <div class="card-header  bg-light border-light p-1">


                                            <b-form-input v-model="searchCateg" class="border-light" id="search-cat" size="sm" placeholder="Chercher ..." type="search"></b-form-input>
                                           
                                            <b-button  @click="AfficherTousArts" size="sm"  class="ml-1 mb-1 mt-1 p-0 text-info text-decoration-none" variant="link">
                                            <i class="fa fa-list-alt mr-1"></i>  
                                            Tous Les Clients              
                                            </b-button> 
                            </div>

                            <b-list-group flush >
                            <b-list-group-item  v-for="item in filterCategorie" :key="item.id" button class="p-2 mt-2" variant="success" @click="cat_click(item.id)">
                                    {{ item.nom }} 
                            </b-list-group-item>

                            </b-list-group>
                            </div>



                    
                </b-collapse>

                <b-collapse id="collap-articl" visible  class="col-5 h-100 mr-0 mt-2 p-0">


                            <div class="card border border-light shadow h-100  overflow-auto">
                            <div class="card-header  bg-light border-light p-1">
                                <b-form-input v-model="serchArt"  class="border-light" id="search-art" size="sm" placeholder="Chercher ..." type="search"></b-form-input>
                            
                                        <div class="custom-control custom-switch form-control-sm ml-1">
                                        <input type="checkbox"
                                        class="custom-control-input" 
                                        id="isCodeBarreSearch"
                                                v-model="isCodeBarreSearch"
                                                name="isCodeBarreSearch"
                                                value=true
                                                unchecked-value=false>

                                        <label class="custom-control-label text-info  mb-0" for="isCodeBarreSearch">Code / CIN</label>
                                        </div>
                            </div>

                            <b-list-group flush  >
                       
                            <b-list-group-item  v-for="item in filterArticle" :key="item.id" button class="p-2 mt-2" variant="info" @click="art_click(item)">
                              {{ item.code }} - {{ item.nom_prenom }} - ( {{ item.cin }} )
                                 </b-list-group-item>

                          

                            </b-list-group>
                            </div>






                </b-collapse>

    <div class="col-1">     
    </div>

    </div>

      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>

 </div>






  
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {


 
    computed: {

    filterArticle() {
            return this.listArt.filter(article => {

            if(this.isCodeBarreSearch)
            {
                if(article.code)
                {
                  return article.code.toLowerCase().includes(this.serchArt.toLowerCase())|| article.cin.toLowerCase().includes(this.serchArt.toLowerCase())         
                }

            }
            else
            {
                return article.nom_prenom.toLowerCase().includes(this.serchArt.toLowerCase())
            }

      })
    },
    filterCategorie() {
            return this.listCat.filter(category => {
            return category.nom.toLowerCase().includes(this.searchCateg.toLowerCase())   
      })
    }
    },
    mounted(){

            this.fetchCategorieList()

    },
    data () {
		return {
            dateToday: new Date().toJSON().slice(0,10).replace(/-/g,'-'),

            isCodeBarreSearch: false,
            searchCateg:'',
            serchArt:'',
            listCat: [],
            listArt: [],

            isSubmitted: false,
            isLoading: false
		}
    },
    methods:{

        fetchCategorieList: function () {

                    this.isLoading=true;
                    this.$http.get('/zones')
                    .then((result) => {
                                this.listCat = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });

        },
        cat_click(id_cat)
        {

                    this.isLoading=true;
                    this.$http.get('/listClients/'+id_cat)
                    .then((result) => {
                                this.listArt = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });

        },
        art_click(cln)
        {



                                            this.$router.push({ 
                                            name: 'paiementClient', 
                                            params: { 
                                            ResivedId: cln.id,
                                            ResivedNom: cln.nom_prenom + " - "+cln.code
                                            } 
                                            });

        },

        AfficherTousArts()
        {
        this.cat_click(0)
        },




        },
        components: {
                    Loading      
        }


}
</script>

<style>
/*------scroll bar---------------------*/

::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #9cc7f9;
  border: 0px none #ffffff;
  border-radius: 0px;
}
::-webkit-scrollbar-thumb:hover {
  background: #9cc7f9;
}
::-webkit-scrollbar-thumb:active {
  background: #9cc7f9;
}
::-webkit-scrollbar-track {
  background: transparent;
  border: 0px none #ffffff;
  border-radius: 60px;
}
::-webkit-scrollbar-track:hover {
  background: transparent;
}
::-webkit-scrollbar-track:active {
  background: transparent;
}
::-webkit-scrollbar-corner {
  background: transparent;
}
.var_color{
  color: #926e99;
}
</style>