<template>
    <div>
      
                <div class="rounded-lg shadow border border-white bg-white mb-4">
                    <div class="row justify-content-centre align-items-center">    
    
    <h4 class="col ml-3 font-weight-lighter text-info"> <span class="fa fa-file-alt"></span> Liste Des Crédits Clients</h4> 

    <h5 class="col m-2 font-weight-lighter text-primary">  Total Des Crédits : {{total_dh}}    DH</h5> 

            <div class="col">

                            <b-button @click="handleSubmitBonCMD" pill size="sm"  class="m-3 float-right" variant="success">
                                <i class="fa fa-print"></i>
                                <span> Liste</span> 
                            </b-button>



                            <b-button @click="exportDox" pill size="sm"  class="m-3 float-right" variant="info">
                                <i class="fa fa-print"></i>
                                <span> Rapport</span> 
                            </b-button>

            </div>
    </div>
       
                </div>  
    
    
    
     
          <div class="rounded-lg shadow border border-white bg-white">

    
    
    
            <b-table 
            sort-icon-left
            head-variant="light"
            responsive 
            :fields="fields" 
            :items="itemslist"
            >
    
    
            </b-table>
          </div>
    
    
    
    
    
        <!-- les trucs public -->
        <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>
    
    
    </div>
</template>
    
    <script>
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import { HeadingLevel,PageNumber, PageNumberFormat,Footer, Header, Table,TableCell,TableRow,Document, Paragraph,WidthType,VerticalAlign,AlignmentType, Packer, TextRun } from "docx";
    import { saveAs } from 'file-saver';
    
    export default {
          computed: {
            entet_pag() {
    
          if(this.$store.getters.get_entete)
          {
                return this.$store.getters.get_entete;
          }
          else
          {
                return '';
          }
        },
        sousentet_pag() {
    
          if(this.$store.getters.get_sousentete)
          {
                return this.$store.getters.get_sousentete;
          }
          else
          {
                return '';
          }
        },
        pied_pag() {
    
          if(this.$store.getters.get_pied)
          {
                return this.$store.getters.get_pied;
          }
          else
          {
                return '';
          }
        },
          total_dh: function() {
          return this.itemslist.reduce((a, b) => +a + +b.solde, 0);
        },
    
    
        },
        mounted(){
        },
        data () {
            return {
    
                itemslist: [],
                fields: [
                    {key: 'code', label: 'Code', sortable: true},
                    {key: 'cin', label: 'CIN', sortable: true},
                    {key: 'nom_prenom', label: 'Client', sortable: true},
                    {key: 'total_bl', label: 'Livraisons', sortable: true},
                    {key: 'total_projet', label: 'BLs Projets', sortable: true}, 
                    {key: 'total_br', label: 'Retours', sortable: true},   
                    {key: 'total_paiem', label: 'Paiements', sortable: true}, 
                    {key: 'solde', label: 'Solde', sortable: true}
                ],
    
   
                isLoading: false
            }
        },

        methods :{
    
    
    exportDox()
            {
    
    
    
    
            let datrw =[new TableRow({
                        children: [
                            new TableCell({
                                width: {
                                    size: 1500,
                                    type: WidthType.DXA,
                                },
                                verticalAlign: VerticalAlign.CENTER,
                                children: [new Paragraph({
                                           
                                            children: [
                                                new TextRun({
                                                    text: "Code",
                                                color: '0f31b5',
                                                bold: false,
                                                font: "Segoe UI"
                                                }),
                                            ],
                                                spacing: {
                                    before: 100,
                                    after: 100
                                },
                                            heading: HeadingLevel.HEADING_3,
                                            alignment: AlignmentType.CENTER,
                                        })],
                            }),
    
    
                            new TableCell({
                                width: {
                                    size: 1500,
                                    type: WidthType.DXA,
                                },
                                verticalAlign: VerticalAlign.CENTER,
                                children: [new Paragraph({
                                            
                                            children: [
                                                new TextRun({
                                                    text: "CIN",
                                                color: '0f31b5',
                                                bold: false,
                                                font: "Segoe UI"
                                                }),
                                            ],
                                            heading: HeadingLevel.HEADING_3,
                                            alignment: AlignmentType.CENTER,
                                        })],
                            }),
    
                                            new TableCell({
                                width: {
                                    size: 2000,
                                    type: WidthType.DXA,
                                },
                                verticalAlign: VerticalAlign.CENTER,
                                children: [new Paragraph({
                                            
                                            children: [
                                                new TextRun({
                                                    text: "Client",
                                                color: '0f31b5',
                                                bold: false,
                                                font: "Segoe UI"
                                                }),
                                            ],
                                            heading: HeadingLevel.HEADING_3,
                                            alignment: AlignmentType.CENTER,
                                        })],
                            }),
                            
                                            new TableCell({
                                width: {
                                    size: 1500,
                                    type: WidthType.DXA,
                                },
                                verticalAlign: VerticalAlign.CENTER,
                                children: [new Paragraph({
                                            
                                            children: [
                                                new TextRun({
                                                    text: "Livraisons",
                                                color: '0f31b5',
                                                bold: false,
                                                font: "Segoe UI"
                                                }),
                                            ],
                                            heading: HeadingLevel.HEADING_3,
                                            alignment: AlignmentType.CENTER,
                                        })],
                            }),
                            new TableCell({
                                width: {
                                    size: 1500,
                                    type: WidthType.DXA,
                                },
                                verticalAlign: VerticalAlign.CENTER,
                                children: [new Paragraph({
                                            
                                            children: [
                                                new TextRun({
                                                    text: "BLs Projets",
                                                color: '0f31b5',
                                                bold: false,
                                                font: "Segoe UI"
                                                }),
                                            ],
                                            heading: HeadingLevel.HEADING_3,
                                            alignment: AlignmentType.CENTER,
                                        })],
                            }),
                             new TableCell({
                                width: {
                                    size: 1500,
                                    type: WidthType.DXA,
                                },
                                verticalAlign: VerticalAlign.CENTER,
                                children: [new Paragraph({
                                            
                                            children: [
                                                new TextRun({
                                                    text: "Retours",
                                                color: '0f31b5',
                                                bold: false,
                                                font: "Segoe UI"
                                                }),
                                            ],
                                            heading: HeadingLevel.HEADING_3,
                                            alignment: AlignmentType.CENTER,
                                        })],
                            }),
                            
                             new TableCell({
                                width: {
                                    size: 1500,
                                    type: WidthType.DXA,
                                },
                                verticalAlign: VerticalAlign.CENTER,
                                children: [new Paragraph({
                                           
                                            children: [
                                                new TextRun({
                                                    text: "Paiements",
                                                color: '0f31b5',
                                                bold: false,
                                                font: "Segoe UI"
                                                }),
                                            ],
                                            heading: HeadingLevel.HEADING_3,
                                            alignment: AlignmentType.CENTER,
                                        })],
                            }),
                                                    
                             new TableCell({
                                width: {
                                    size: 1500,
                                    type: WidthType.DXA,
                                },
                                verticalAlign: VerticalAlign.CENTER,
                                children: [new Paragraph({
                                            children: [
                                                new TextRun({
                                                    text: "Solde",
                                                color: '0f31b5',
                                                bold: false,
                                                font: "Segoe UI"
                                                }),
                                            ],
                                            heading: HeadingLevel.HEADING_3,
                                            alignment: AlignmentType.CENTER,
                                        })],
                            }),
    
                        ],
                    })]
    
            this.itemslist.forEach(function(item){
    
            let r = new TableRow({
                        children: [
                            new TableCell({
                                width: {
                                    size: 1500,
                                    type: WidthType.DXA,
                                },
                                verticalAlign: VerticalAlign.CENTER,
                                children: [new Paragraph({
                                           
                                            children: [
                                            new TextRun({
                                                text: item.code,
                                                color: '000000',
                                                bold: true,
                                                }),
                                                    ],
                                            spacing: {
                                            before: 50,
                                            after: 50
                                        },
                                            heading: HeadingLevel.HEADING_3,
                                            alignment: AlignmentType.CENTER,
                                        })],
                            }),
    
                            new TableCell({
                                width: {
                                    size: 1500,
                                    type: WidthType.DXA,
                                },
                                verticalAlign: VerticalAlign.CENTER,
                                children: [new Paragraph({
                                            
                                            children: [
                                            new TextRun({
                                                text: item.cin,
                                                color: '000000',
                                                bold: true,
                                                }),
                                                    ],
                                            heading: HeadingLevel.HEADING_3,
                                            alignment: AlignmentType.CENTER,
                                        })],
                            }),
    
                            new TableCell({
                                width: {
                                    size: 2000,
                                    type: WidthType.DXA,
                                },
                                verticalAlign: VerticalAlign.CENTER,
                                children: [new Paragraph({
                                            
                                            children: [
                                            new TextRun({
                                                text: item.nom_prenom,
                                                color: '000000',
                                                bold: true,
                                                }),
                                                    ],
                                            heading: HeadingLevel.HEADING_3,
                                            alignment: AlignmentType.CENTER,
                                        })],
                            }),
    
                            new TableCell({
                                width: {
                                    size: 1500,
                                    type: WidthType.DXA,
                                },
                                verticalAlign: VerticalAlign.CENTER,
                                children: [new Paragraph({
                                            
                                            children: [
                                            new TextRun({
                                                text: item.total_bl.toString(),
                                                color: '000000',
                                                bold: true,
                                                }),
                                                    ],
                                            heading: HeadingLevel.HEADING_3,
                                            alignment: AlignmentType.CENTER,
                                        })],
                            }),
                                new TableCell({
                                width: {
                                    size: 1500,
                                    type: WidthType.DXA,
                                },
                                verticalAlign: VerticalAlign.CENTER,
                                children: [new Paragraph({
                                            
                                            children: [
                                            new TextRun({
                                                text: item.total_projet.toString(),
                                                color: '000000',
                                                bold: true,
                                                }),
                                                    ],
                                            heading: HeadingLevel.HEADING_3,
                                            alignment: AlignmentType.CENTER,
                                        })],
                            }),
                            new TableCell({
                                width: {
                                    size: 1500,
                                    type: WidthType.DXA,
                                },
                                verticalAlign: VerticalAlign.CENTER,
                                children: [new Paragraph({
                                            
                                            children: [
                                            new TextRun({
                                                text: item.total_br.toString(),
                                                color: '000000',
                                                bold: true,
                                                }),
                                                    ],
                                            heading: HeadingLevel.HEADING_3,
                                            alignment: AlignmentType.CENTER,
                                        })],
                            }),
                            
                            new TableCell({
                                width: {
                                    size: 1500,
                                    type: WidthType.DXA,
                                },
                                verticalAlign: VerticalAlign.CENTER,
                                children: [new Paragraph({
                                           
                                            children: [
                                            new TextRun({
                                                text: item.total_paiem.toString(),
                                                color: '000000',
                                                bold: true,
                                                }),
                                                    ],
                                            heading: HeadingLevel.HEADING_3,
                                            alignment: AlignmentType.CENTER,
                                        })],
                            }),
                            
                            new TableCell({
                                width: {
                                    size: 1500,
                                    type: WidthType.DXA,
                                },
                                verticalAlign: VerticalAlign.CENTER,
                                children: [new Paragraph({
                                            
                                            children: [
                                            new TextRun({
                                                text: item.solde.toString(),
                                                color: '000000',
                                                bold: true,
                                                }),
                                                    ],
                                            heading: HeadingLevel.HEADING_3,
                                            alignment: AlignmentType.CENTER,
                                        })],
                            }),
    
    
                        ],
                    })
    
            datrw.push(
            r
            )
            });
    
        
    
            const table = new Table({
    
                rows:  datrw
            })
    
    const doc = new Document({
    
        sections: [
            {
    
                       properties: {
                            page: {
                                pageNumbers: {
                                    start: 1,
                                    formatType: PageNumberFormat.DECIMAL,
                                },
                                margin:{
                                    left:500,
                                    right:500
                                }
                            },
                        },
                        headers: {
                            default: new Header({
                                children: [
                            new Paragraph({
                                        children: [
                                new TextRun({
                                    text: this.entet_pag,
                                    color: '000000',
                                    bold: true,
    
                                    }),
                                        ],
                                        heading: HeadingLevel.TITLE,
                                        alignment: AlignmentType.CENTER,
                                    }),
    
                            new Paragraph({
                                        children: [
                                new TextRun({
                                    text: this.sousentet_pag,
                                    color: '000000',
                                    bold: false,
                                    font: "Segoe UI"
                                    })
    
                                        ],
                                    border: {
                                    bottom: {
                                            color: "000000",
                                            space: 4,
                                            value: "single",
                                            size: 10,
                                        }},
                                        heading: HeadingLevel.HEADING_1,
                                        alignment: AlignmentType.CENTER,
                                    }),
    
    
    
    
                                ],
                            }),
                        },
                        footers: {
                            default: new Footer({
                                children: [
    
                            new Paragraph({
                                        children: [
                                new TextRun({
                                    text: this.pied_pag,
                                    color: '000000',
                                    bold: false,
                                    })
    
                                        ],
                                    border: {
                                    top: {
                                            color: "000000",
                                            space: 4,
                                            value: "single",
                                            size: 10,
                                        }},
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    }),
    
                                    new Paragraph({
                                        alignment: AlignmentType.RIGHT,
                                        children: [
                                            new TextRun({
                                                children: ["Page : ", PageNumber.CURRENT],
                                                color: '000000'
                                            }),
                                            new TextRun({
                                                children: [" Sur ", PageNumber.TOTAL_PAGES],
                                                color: '000000'
                                            }),
                                        ],
                                    }),
                                ],
                            }),
                        },
    
    
    
                        children: [
    
                            new Paragraph({
    
    
    
                                children: [
    
                                    new TextRun({
                                    text: "Liste Des Crédits Clients",
                                    color: '000000',
                                    bold: false,
                                    font: "Segoe UI"
                                }), 

    
                                ],
    
                                heading: HeadingLevel.HEADING_2,
                                                    alignment: AlignmentType.CENTER,
    
                                    spacing: {
                                        after: 300,
                                        before: 300
                                    },
                            }),
    
        
                            new Paragraph({
    
    
    
                                children: [

                                    new TextRun({
                                    text: "Total Des Crédits : "+this.total_dh + " DH",
                                    color: '000000',
                                    bold: false,
                                    font: "Segoe UI"
                                }), 


                                ],

                                heading: HeadingLevel.HEADING_2,
                                                    alignment: AlignmentType.CENTER,

                                    spacing: {
                                        after: 300,
                                        before: 300
                                    },
                            }),
    
    
                            table
                            
                            ]
    
            }]
    
    });
    
    
    
    
                Packer.toBlob(doc).then(blob => {
    
            saveAs(blob, "crédits_clients.docx");
    
                });
    
            },
    
    
    
    
    
    
    
    
            handleSubmitBonCMD(){
    
                this.isLoading=true;
                        
                        this.$http.get('/listCreditsClients')
                        .then((result) => {
                                    this.itemslist = result.data.data
                                    this.isLoading=false;
                        })
                        .catch(error=> {
                                    console.log(error)
                                    this.isLoading=false;
                                    this.flashMessage.show({
                                    status: 'error',
                                    title: 'Erreur de Chargement',
                                    message: 'Impossible charger les données.. Actualisez et Réessayez'
                                    })
                        });

            },
    

    
        },
        components: {
                  Loading      
        }
    }
    </script>
    
    <style  scoped>
    
    </style>