<template>
  

<div  class="card  shadow border border-white bg-white h-100 pb-3 overflow-auto">
        <div class="row pb-1 border-bottom border-info m-3">


                <h4 class="font-weight-lighter var_color"> <span class="fa fa-folder-open"></span> Suivi Des Paiements Fournisseurs</h4> 
            
        </div>














<div class="h-100 m-2">
  

            <div class="col-12 mb-3 ">

              <div class="card shadow h-100 pr-3 pl-3">




  <b-input-group prepend="Fournisseurs " class="mt-3">

            <select class="form-control" id="selectedFournis" v-model="selectedFournis">
                <option v-for="item in fournisseurs" :key="item.id" v-bind:value="{ id: item.id, nom: item.nom ,nrb_jour: item.nrb_jour}">   
                {{ item.nom }}
                </option>

           </select>



    <b-input-group-append>
      <b-button pill class="ml-2" variant="outline-info"  @click="click_RechrchFourns(0)">
          <span class="fa fa-search"></span>
          Situation Courante
        </b-button>


      <b-button pill class="ml-2" variant="outline-primary"  @click="click_RechrchFourns(1)">
          <span class="fa fa-search"></span>
          Situation Réglée
        </b-button>

        <b-button v-if="selectedFournis.id && situCourent==true && total_Solde==0" pill class="ml-2" variant="outline-success"  @click="click_regler_situation">
          <span class="fa fa-check"></span>
          Régler La Situation
        </b-button>

    </b-input-group-append>
  </b-input-group>






                                     

                            <div class="row mt-3">
                                        <div class="col">
                                                                <div class=" d-flex justify-content-center pt-1 text-secondary">
                                                                        <h5 class="font-weight-light ">Livraisons : {{total_livraison}} </h5>
                                                                </div>
                                        </div>

                                        <div class="col">

                                        <div class=" d-flex justify-content-center pt-1 text-secondary">
                                                <h5 class="font-weight-light ">Projets: {{total_projet}} </h5>
                                        </div>
                                        </div>


                                        <div class="col">
                                                                <div class=" d-flex justify-content-center pt-1 text-secondary">
                                                                        <h5 class="font-weight-light ">Avoirs : {{total_Avoir}} </h5>
                                                                </div>
                                        </div>

                                        <div class="col">
                                                                <div class=" d-flex justify-content-center pt-1 text-secondary">
                                                                        <h5 class="font-weight-light ">Paiements : {{total_paiement}} </h5>
                                                                </div>
                                        </div>
                                        <div class="col">
                                                                <div class=" d-flex justify-content-center pt-1 text-primary">
                                                                        <h5 class="font-weight-light ">Solde : {{total_Solde}} </h5>
                                                                </div>
                                        </div>

                            </div>
              </div>
            </div>





            <div class="col-12 mb-3 ">

              <div class="card h-100 py-1">
                <div class="card-body p-1">




                <div class="float-left pt-1 text-secondary">
                    <h5 class="font-weight-normal ml-2">Paiements</h5>
                </div>



                <b-button v-if="selectedFournis.id && situCourent==true"   class="ml-2 mt-0  mb-2 float-left text-success" variant="link" @click="add_Paiem_click($event.target)">
                            <i class="fa fa-plus-square"></i>

                </b-button>

                <b-button v-if="selectedFournis.id && situCourent==true" pill   class="float-right mr-3" variant="outline-success" @click="exportDox()">
                            <i class="fa fa-print"></i>
                            Imprimer
                </b-button>

                            <b-table 
                            small 
                            responsive
                            sort-icon-left
                            sticky-header
                            :tbody-tr-class="rowClass"
                            :fields="fields" 
                            :items="itemslist"
                            >
                                <!-- A custom formatted header cell for field 'name' -->
                                <template #head(date)="data">
                                    <span class="text-info font-weight-light">{{ data.label }}</span>
                                </template>

                                <template #head(montant)="data">
                                    <span class="text-info font-weight-light">{{ data.label }}</span>
                                </template>

                                <template #head(mode_oper)="data">
                                    <span class="text-info font-weight-light">{{ data.label }}</span>
                                </template>
                                <template #head(infos_oper)="data">
                                    <span class="text-info font-weight-light">{{ data.label }}</span>
                                </template>

                                <template #head(date_ech)="data">
                                    <span class="text-info font-weight-light">{{ data.label }}</span>
                                </template>

                                <template #head(etat)="data">
                                    <span class="text-info font-weight-light">{{ data.label }}</span>
                                </template>
                                <template #head(regler)="data">
                                    <span class="text-info font-weight-light">{{ data.label }}</span>
                                </template>

                                <template #head(user)="data">
                                    <span class="text-info font-weight-light">{{ data.label }}</span>
                                </template>
                                <template #head(description)="data">
                                    <span class="text-info font-weight-light">{{ data.label }}</span>
                                </template>


                                <template #cell(actions)="row">
                                        <b-button key="supp" v-if="row.item.etat==0 && row.item.regler==0" pill   class="mr-1 float-right text-danger" variant="link" @click="del_click(row.item)">
                                                    <i class="fa fa-trash-alt"></i>
                                            
                                        </b-button>

                                        <b-button key="edit" v-if="row.item.etat==0 && row.item.regler==0" pill  class="mr-1 float-right text-primary" variant="link" @click="edit_click(row.item, row.index, $event.target)">
                                                <i class="fa fa-pencil-alt"></i>
                                        
                                        </b-button>

                                        <b-button key="val" v-if="row.item.etat==0 && row.item.regler==0" pill   class="mr-1 float-right text-success" variant="link" @click="validerPaiement(row.item, 1)">
                                                    <i class="fa fa-check-square"></i>
                                            
                                        </b-button>
                                        <b-button key="deval" v-if="row.item.etat==1 && row.item.regler==0" pill  class="mr-1 float-right text-danger" variant="link" @click="validerPaiement(row.item, 0)">
                                                <i class="fa fa-check-square"></i>
                                        
                                        </b-button>
                                    </template>
                            
                            </b-table>
          


                </div>
              </div>
            </div>



            <div class="row m-0">
            <div class="col-6">
              <div class="card  h-100 py-1">
                <div class="card-body p-0">

                <div class="float-left pt-1 text-secondary">
                    <h5 class="font-weight-normal ml-2"> Livraisons</h5>
                </div>



                <b-table 
                            small 
                            responsive
                            sort-icon-left
                            sticky-header


                            :fields="fieldsLiv" 
                            :items="itemslistLivraison"
                            >


                                <!-- A custom formatted header cell for field 'name' -->
                                <template #head(nom)="data">
                                    <span class="text-info font-weight-light">{{ data.label }}</span>
                                </template>

                                <template #head(date)="data">
                                    <span class="text-info font-weight-light">{{ data.label }}</span>
                                </template>

                                <template #head(total_ttc)="data">
                                    <span class="text-info font-weight-light">{{ data.label }}</span>
                                </template>

                                <template #cell(actions)="row">

                                        <b-button pill v-if="row.item.regler==0"  class="mr-2 float-right text-primary" variant="link" @click="payeLivrais_click(row.item, row.index, $event.target)">
                                                <i class="fa fa-coins"></i>   
                                        </b-button>
                                        
                                        <b-button pill size="sm" class="mr-2 float-right" variant="link" @click="fetchListArts(row.item)">
                                                <i class="fa fa-bars"></i>
                                        </b-button>
                                    </template>
                            
                </b-table>

                </div>
              </div>
            </div>

            <div class="col-6">
              <div class="card   h-100 py-1">
                <div class="card-body p-0">

                <div class="float-left pt-1 text-secondary">
                    <h5 class="font-weight-normal ml-2"> Avoirs</h5>
                </div>




                <b-table 
                            small 
                            responsive
                            sort-icon-left
                            sticky-header


                            :fields="fieldsAvoir" 
                            :items="itemslistAvoir"
                            >

                                <!-- A custom formatted header cell for field 'name' -->
                                <template #head(nom)="data">
                                    <span class="text-info font-weight-light">{{ data.label }}</span>
                                </template>

                                <template #head(date)="data">
                                    <span class="text-info font-weight-light">{{ data.label }}</span>
                                </template>

                                <template #head(total_ttc)="data">
                                    <span class="text-info font-weight-light">{{ data.label }}</span>
                                </template>

                                <template #cell(actions)="row">

                                        <b-button v-if="row.item.regler==0" pill  class="mr-2 float-right text-primary" variant="link" @click="payeAvoir_click(row.item, row.index, $event.target)">
                                                <i class="fa fa-coins"></i>
                                        </b-button>

                                        
                                        <b-button pill size="sm" class="mr-2 float-right" variant="link" @click="fetchListArtsRetour(row.item)">
                                                <i class="fa fa-bars"></i>
                                        </b-button>
                                    </template>
                            
                </b-table>

                </div>
              </div>
            </div>
</div>


<div class="row m-0">
            <div class="col-12  mt-4">
              <div class="card  h-100 py-1">
                <div class="card-body p-0">

                <div class="float-left pt-1 text-secondary">
                    <h5 class="font-weight-normal ml-2"> Livraisons Des Projets</h5>
                </div>



                <b-table 
                            small 
                            responsive
                            sort-icon-left
                            sticky-header


                            :fields="fieldsLivProj" 
                            :items="itemslistLivrProjet"
                            >


                                <!-- A custom formatted header cell for field 'name' -->
                                <template #head(nom)="data">
                                    <span class="text-info font-weight-light">{{ data.label }}</span>
                                </template>

                                <template #head(date)="data">
                                    <span class="text-info font-weight-light">{{ data.label }}</span>
                                </template>

                                <template #head(total_ttc)="data">
                                    <span class="text-info font-weight-light">{{ data.label }}</span>
                                </template>

                                <template #head(description)="data">
                                    <span class="text-info font-weight-light">{{ data.label }}</span>
                                </template>
                                <template #cell(actions)="row">

                                        <b-button pill v-if="row.item.regler==0"  class="mr-2 float-right text-success" variant="link" @click="payeLivrais_click(row.item, row.index, $event.target)">
                                                <i class="fa fa-coins"></i>
                                        </b-button>


                                </template>
                            
                </b-table>

                </div>
              </div>
            </div>


</div>




  
</div> 


    <!-- le modal -->
    <b-modal id="banqModal" title="Paiement" size="lg" hide-footer>
        <form @submit.prevent="handleSubmit">



            <div class="row">

                <div class="form-group col">
                <label for="date_exp" class="mr-sm-2">Date :</label>
                    <b-form-datepicker
                    class="mr-sm-2"
                    id="datepicker-date_exp"
                    placeholder="date"
                    v-model="itemForm.date"
                    :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                    locale="fr" />
            </div>


            <div class="form-group col">
                <label for="date_ech" class="mr-sm-2">Date Echéance: <span v-if="selectedFournis.nrb_jour > 0" class="text-danger">({{selectedFournis.nrb_jour}} Jour)</span></label>
                    <b-form-datepicker
                    class="mr-sm-2"
                    id="datepicker-date_ech"
                    placeholder="Date Echéance"
                    v-model="itemForm.date_ech"
                    :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                    locale="fr" />
            </div>

            </div>

            <div class="form-group">
                <label for="montant">Montant (DH) :</label>
                <input type="text" v-model="itemForm.montant" id="montant" name="montant" class="form-control"
                    :class="{ 'is-invalid': isSubmitted && $v.itemForm.montant.$error }" />
                <div v-if="isSubmitted && !$v.itemForm.montant.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                <div v-if="isSubmitted && !$v.itemForm.montant.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
            </div>

            <div class="row">

                        <div class="form-group col">
                                        <label for="mode">Mode de paiement :</label>
                                        <select class="form-control" id="mode" v-model="itemForm.mode_oper"
                                         :class="{ 'is-invalid': isSubmitted && $v.itemForm.mode_oper.$error }">
                                        <option v-for="item in modes" :key="item.id" v-bind:value="item.mode">   
                                            {{ item.mode }}
                                        </option>
                                        </select>
                                    <div v-if="isSubmitted && !$v.itemForm.mode_oper.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                        </div>
                        <div class="form-group col">
                            <label for="infos">Infos de paiement :</label>
                            <input type="text" v-model="itemForm.infos_oper" id="infos" name="infos" class="form-control" />

                        </div>
            </div>


            <div v-if="ModalEdit==false" class="row">

                            <div class="form-group col">
                            <div class="custom-control custom-switch">
                            <input type="checkbox"
                            class="custom-control-input" 
                            id="etat"
                                    v-model="itemForm.etat"
                                    name="etat"
                                    value=true
                                    unchecked-value=false>

                            <label class="custom-control-label" for="etat">Payé :</label>
                            </div>
                            </div>

                        <div v-if="itemForm.etat  && d_is_tresor==true"  class="form-group col">
                                        <label for="bank_compte_id">Compte :</label>
                                        <select class="form-control" id="bank_compte_id" v-model="itemForm.bank_compte_id"
                                        :class="{ 'is-invalid': isSubmitted && $v.itemForm.bank_compte_id.$error }">
                                        <option v-for="item in comptes" :key="item.id" v-bind:value="item.id">   
                                            {{ item.nom }}
                                        </option>
                                        </select>

                            <div v-if="isSubmitted && !$v.itemForm.bank_compte_id.required" class="invalid-feedback">Ce champ est obligatoire !!</div>

                        </div>

                     </div>




            <div class="form-group">
            <label for="Description">Description :</label>
            <textarea name="description" id="description"  rows="2" class="form-control" v-model="itemForm.description"></textarea>

            </div>

            <div class="form-group">
                <button type="submit" class="btn  btn-primary btn-block">{{ModalType}}</button>
            </div>

        </form>
    </b-modal>


    <!-- le modal -->
    <b-modal id="comptModal" title="Sélectionnez Un compte" hide-footer>
        <form @submit.prevent="handleSubmitValidPayem">



                <div class="form-group col">
                            <label for="bank_compte_idval">Compte :</label>
                            <select class="form-control" id="bank_compte_idval" v-model="itemFormValid.bank_compte_id">
                            <option v-for="item in comptes" :key="item.id" v-bind:value="item.id">   
                                {{ item.nom }}
                            </option>
                            </select>

            </div>

            <div class="form-group">
                <button type="submit" class="btn  btn-primary btn-block mt-4">Valider / Dévalider</button>
            </div>



        </form>
    </b-modal>
 
    <!-- le modal -->
    <b-modal id="detailModal" :title=curentBN size="lg" hide-footer>


<b-table 
 small 
 borderless
 striped 
 sticky-header
 :fields="fieldsArticle" 
 :items="ArticlesList"
 >

 
 </b-table>

</b-modal>
    









      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>

 </div>






  
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required,decimal} from "vuelidate/lib/validators";
import { HeadingLevel,PageNumber, PageNumberFormat,Footer, Header, Table,TableCell,TableRow,Document, Paragraph,WidthType,VerticalAlign,AlignmentType, Packer, TextRun } from "docx";
import { saveAs } from 'file-saver';

export default {

computed: {
    d_is_tresor() {
      return this.$store.getters.get_is_tresor;
    },
            entet_pag() {

      if(this.$store.getters.get_entete)
      {
            return this.$store.getters.get_entete;
      }
      else
      {
            return '';
      }
    },
    sousentet_pag() {

      if(this.$store.getters.get_sousentete)
      {
            return this.$store.getters.get_sousentete;
      }
      else
      {
            return '';
      }
    },
    pied_pag() {

      if(this.$store.getters.get_pied)
      {
            return this.$store.getters.get_pied;
      }
      else
      {
            return '';
      }
    },
    user_name() {

      if(this.$store.getters.get_user)
      {
            return this.$store.getters.get_user.name;
      }
      else
      {
            return '';
      }
    },
      total_paiement: function() {

return this.itemslist.filter(({etat}) => etat === 1).reduce((a, b) => +a + +b.montant, 0);
    },
      total_livraison: function() {
      return this.itemslistLivraison.reduce((a, b) => +a + +b.total_ttc, 0);
    },
      total_Avoir: function() {
      return this.itemslistAvoir.reduce((a, b) => +a + +b.total_ttc, 0);
    },
    total_projet: function() {
      return this.itemslistLivrProjet.reduce((a, b) => +a + +b.total_ttc, 0);
    },
    total_Solde: function() {
      return this.total_livraison+this.total_projet  - this.total_Avoir - this.total_paiement;
    },

    },
    mounted(){

      this.fetchModesFourniss()

    },
    data () {
		return {

            selectedFournis: {
                    id:'',
                    nom:'',
                    nrb_jour:0
            },


            situCourent:false,
            fournisseurs:[],
            modes:[],
            comptes:[],
            itemslist: [],
            itemForm: {
                    id:'',
                    date:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    montant:'',
                    mode_oper:'',
                    infos_oper:'',
                    date_ech:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    description:'',
                    etat:0,
                    regler:0,
                    user:'user',
                    fournisseur_id:'',
                    bank_compte_id:''},
            fields: [
                {key: 'date', label: 'Date'},
                {key: 'montant', label: 'Montant'},
                {key: 'mode_oper', label: 'Mode'},
                {key: 'infos_oper', label: 'Info'},
                {key: 'date_ech', label: 'Date Ech'},
                {key: 'etat', label: 'Payé',
                 formatter: (value) => {
                 return value ? 'Oui' : 'Non'
                }},
                {key: 'regler', label: 'Réglé',
                                 formatter: (value) => {
                 return value ? 'Oui' : 'Non'
                }},
                {key: 'user', label: 'utilisateur'},
                {key: 'description', label: 'Description'},
                { key: 'actions', label: '' }
            ],

            //livraison:
            itemslistLivraison: [],
            fieldsLiv: [

                {key: 'nom', label: 'N°'},
                {key: 'date', label: 'Date'},
                {key: 'total_ttc', label: 'Total TTC'},
                { key: 'actions', label: '' }
            ],
            fieldsLivProj: [

            {key: 'nom', label: 'N°'},
            {key: 'date', label: 'Date'},
            {key: 'total_ttc', label: 'Total TTC'},
            {key: 'description', label: 'Description'},
            { key: 'actions', label: '' }
            ],
            //Avoirs
            itemslistAvoir: [],
            fieldsAvoir: [
                {key: 'nom', label: 'N°'},
                {key: 'date', label: 'Date'},
                {key: 'total_ttc', label: 'Total TTC'},
                { key: 'actions', label: '' }
            ],
            //details
            fieldsArticle: [
                {key: 'ref', label: 'Réf'},
                {key: 'qte', label: 'Quantité'},
                {key: 'unite', label: 'Unité'},
                {key: 'prix', label: 'Prix'},
                {key: 'nom_lot', label: 'Lot'},
                {key: 'description', label: 'Description'}
            ],
            ArticlesList:[],

            itemslistLivrProjet:[],

            //public trucs
            ModalEdit: false,
            ModalType:'Ajouter',
            isSubmitted: false,
            isLoading: false,
            itemFormValid: {
                    id:'',
                    date:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    etat:0,
                    user:'user',
                    bank_compte_id:''},



		}
    },
    validations: {
            itemForm: {
                date: {
                    required
                },
                montant: {
                    required,
                    decimal
                },
                mode_oper: {
                    required
                },
                date_ech: {
                    required
                },
                fournisseur_id: {
                    required
                },
                bank_compte_id: {
                    required
                }

            }

    },
 methods:{
        exportDox()
        {
let situatType = ""

if(this.situCourent == true)
{
situatType="Situation Courante"
}
else
{
situatType="Situation Réglée"
}

       

      let  cent= this.selectedFournis.nom
 

//paiements
        let datrw =[new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                            children: [
                                            new TextRun({
                                            text: "Date",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                            spacing: {
                                                before: 100,
                                                after: 100
                                            },
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({

                                                                                children: [
                                            new TextRun({
                                                text: "Montant",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                                        new TableCell({
                                                width: {
                                                    size: 2000,
                                                    type: WidthType.DXA,
                                                },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({

                            children: [
                                            new TextRun({
                                            text: "Date Ech",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),
                        new TableCell({
                                                width: {
                                                    size: 2000,
                                                    type: WidthType.DXA,
                                                },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({

                                                                    children: [
                                            new TextRun({
                                            text: "Mode",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),
                        
                        new TableCell({
                                                width: {
                                                    size: 2000,
                                                    type: WidthType.DXA,
                                                },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                                                    children: [
                                            new TextRun({
                                            text: "Info",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],

                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                                        new TableCell({
                            width: {
                                size: 4000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({

                                        children: [
                                            new TextRun({
                                            text: "Description",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],

                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),
                        new TableCell({
                                                width: {
                                                    size: 2000,
                                                    type: WidthType.DXA,
                                                },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({

                                        children: [
                                            new TextRun({
                                            text: "Etat",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],

                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                    ],
                })]

        this.itemslist.forEach(function(item){

        let toto = ""

        if(item.etat ==1)
        {
        toto= "Payé";
        }
        else
        {
        toto= "NON Payé";
        }


        let r = new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({

                            children: [
                                        new TextRun({
                                            text: item.date,
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                        spacing: {
                                        before: 50,
                                        after: 50
                                    },
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({

                                        children: [
                                        new TextRun({
                                            text: item.montant.toString(),
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({

                                        children: [
                                        new TextRun({
                                            text: item.date_ech,
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({

                                        children: [
                                        new TextRun({
                                            text: item.mode_oper,
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),
                        
                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({

                                        children: [
                                        new TextRun({
                                            text: item.infos_oper,
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),
                        new TableCell({
                            width: {
                                size: 4000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({

                                        children: [
                                        new TextRun({
                                            text: item.description,
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),
                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({

                                        children: [
                                        new TextRun({
                                            text: toto,
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                    ],
                })


        datrw.push(
        r
        )
        });

        const table = new Table({

            rows:  datrw
        })


//BL
        let datrwbl =[new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 4000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({

                                        children: [
                                            new TextRun({
                                                text: "N°",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                            spacing: {
                                                before: 100,
                                                after: 100
                                            },
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 4000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({

                                        children: [
                                            new TextRun({
                                                text: "Date",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                                        new TableCell({
                                                width: {
                                                    size: 4000,
                                                    type: WidthType.DXA,
                                                },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({

                                        children: [
                                            new TextRun({
                                                text: "Total TTC",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),



                    ],
                })]

        this.itemslistLivraison.forEach(function(item){

        let rbl = new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 4000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                    
                                        children: [
                                        new TextRun({
                                            text: item.nom,
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                        spacing: {
                                        before: 50,
                                        after: 50
                                    },
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 4000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                       
                                        children: [
                                        new TextRun({
                                            text: item.date,
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 4000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({

                                        
                                        children: [
                                        new TextRun({
                                            text: item.total_ttc.toString(),
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),



                    ],
                })

        datrwbl.push(
        rbl
        )
        });

        const tablebl = new Table({

            rows:  datrwbl
        })

//Avoir
        let datrwav =[new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 4000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        
                                        children: [
                                            new TextRun({
                                                text: "N°",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                            spacing: {
                                                before: 100,
                                                after: 100
                                            },
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 4000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({

                                        
                                        children: [
                                            new TextRun({
                                                text: "Date",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                                        new TableCell({
                                                width: {
                                                    size: 4000,
                                                    type: WidthType.DXA,
                                                },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({

                                        
                                        children: [
                                            new TextRun({
                                                text: "Total TTC",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),



                    ],
                })]

        this.itemslistAvoir.forEach(function(item){

        let rav = new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 4000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({          
                                        children: [
                                        new TextRun({
                                            text: item.nom,
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                        spacing: {
                                        before: 50,
                                        after: 50
                                    },
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 4000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                          
                                        children: [
                                        new TextRun({
                                            text: item.date,
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 4000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                                           
                                        children: [
                                        new TextRun({
                                            text: item.total_ttc.toString(),
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),



                    ],
                })

        datrwav.push(
        rav
        )
        });


        const tableav = new Table({

            rows:  datrwav
        })

//BL Projet
let datrwblproj =[new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 4000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({

                                        children: [
                                            new TextRun({
                                                text: "N°",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                            spacing: {
                                                before: 100,
                                                after: 100
                                            },
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 4000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({

                                        children: [
                                            new TextRun({
                                                text: "Date",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                                        new TableCell({
                                                width: {
                                                    size: 4000,
                                                    type: WidthType.DXA,
                                                },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({

                                        children: [
                                            new TextRun({
                                                text: "Total TTC",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),



                    ],
                })]

        this.itemslistLivrProjet.forEach(function(item){

        let rblproj = new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 4000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                    
                                        children: [
                                        new TextRun({
                                            text: item.nom,
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                        spacing: {
                                        before: 50,
                                        after: 50
                                    },
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 4000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                       
                                        children: [
                                        new TextRun({
                                            text: item.date,
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 4000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({

                                        
                                        children: [
                                        new TextRun({
                                            text: item.total_ttc.toString(),
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),



                    ],
                })

        datrwblproj.push(
        rblproj
        )
        });

        const tableblproj = new Table({

            rows:  datrwblproj
        })

        const doc = new Document({

    sections: [
        {


                    properties: {
                        page: {
                            pageNumbers: {
                                start: 1,
                                formatType: PageNumberFormat.DECIMAL,
                            },
                            margin:{
                                left:500,
                                right:500
                            }
                            
                        },
                    },
                    headers: {
                        default: new Header({
                            children: [
                        new Paragraph({
                                    children: [
                            new TextRun({
                                text: this.entet_pag,
                                color: '000000',
                                bold: true,

                                }),
                                    ],
                                    heading: HeadingLevel.TITLE,
                                    alignment: AlignmentType.CENTER,
                                }),

                        new Paragraph({
                                    children: [
                            new TextRun({
                                text: this.sousentet_pag,
                                color: '000000',
                                bold: false,
                                })

                                    ],
                                border: {
                                bottom: {
                                        color: "000000",
                                        space: 4,
                                        value: "single",
                                        size: 10,
                                    }},
                                    heading: HeadingLevel.HEADING_1,
                                    alignment: AlignmentType.CENTER,
                                }),




                            ],
                        }),
                    },
                    footers: {
                        default: new Footer({
                            children: [

                        new Paragraph({
                                    children: [
                            new TextRun({
                                text: this.pied_pag,
                                color: '000000',
                                bold: false,
                                })

                                    ],
                                border: {
                                top: {
                                        color: "000000",
                                        space: 4,
                                        value: "single",
                                        size: 10,
                                    }},
                                    heading: HeadingLevel.HEADING_3,
                                    alignment: AlignmentType.CENTER,
                                }),

                                new Paragraph({
                                    alignment: AlignmentType.RIGHT,
                                    children: [
                                        new TextRun({
                                            children: ["Page : ", PageNumber.CURRENT],
                                            color: '000000'
                                        }),
                                        new TextRun({
                                            children: [" Sur ", PageNumber.TOTAL_PAGES],
                                            color: '000000'
                                        }),
                                    ],
                                }),
                            ],
                        }),
                    },




                    children: [

                        new Paragraph({



                            children: [

                                new TextRun({
                                text: "Suivi Des Paiements Fournisseurs ( "+situatType+" )",
                                color: '000000',
                                bold: false,
                                font: "Segoe UI"
                            })

                            ],

                            heading: HeadingLevel.HEADING_2,
                                                alignment: AlignmentType.CENTER,

                                spacing: {
                                    after: 300,
                                    before: 300
                                },
                        }),



                        new Paragraph({



                            children: [

                                new TextRun({
                                text: "Fournisseur : "+cent+"                      Solde : "+this.total_Solde+" DH",
                                color: '000000',
                                bold: false,
                                font: "Segoe UI"
                            })
                            ],
                                border: {
                                bottom: {
                                        color: "000000",
                                        space: 4,
                                        value: "single",
                                        size: 5,
                                    }},
                            heading: HeadingLevel.HEADING_4,
                            alignment: AlignmentType.CENTER,

                                spacing: {
                                    after: 300,
                                    before: 100
                                },
                        }),




                        new Paragraph({



                            children: [

                                new TextRun({
                                text: "Paiements : "+this.total_paiement + " DH",
                                color: '000000',
                                bold: false,
                                font: "Segoe UI"
                            })
                            ],

                            heading: HeadingLevel.HEADING_2,
                            alignment: AlignmentType.CENTER,

                                spacing: {
                                    after: 100,
                                    before: 100
                                },
                        }),

                        table,

                                                new Paragraph({



                            children: [

                                new TextRun({
                                text: "Livraisons : " +this.total_livraison + " DH",
                                color: '000000',
                                bold: false,
                                font: "Segoe UI"
                            })
                            ],

                            heading: HeadingLevel.HEADING_2,
                            alignment: AlignmentType.CENTER,

                                spacing: {
                                    after: 100,
                                    before: 100
                                },
                        }),


                        tablebl,

                        new Paragraph({



                            children: [

                                new TextRun({
                                text: "Avoirs : "+this.total_Avoir + " DH",
                                color: '000000',
                                bold: false,
                                font: "Segoe UI"
                            })
                            ],

                            heading: HeadingLevel.HEADING_2,
                            alignment: AlignmentType.CENTER,

                                spacing: {
                                    after: 100,
                                    before: 100
                                },
                        }),
                        tableav,
                        new Paragraph({



                            children: [

                                new TextRun({
                                text: "Projets : "+this.total_projet + " DH",
                                color: '000000',
                                bold: false,
                                font: "Segoe UI"
                            })
                            ],

                            heading: HeadingLevel.HEADING_2,
                            alignment: AlignmentType.CENTER,

                                spacing: {
                                    after: 100,
                                    before: 100
                                },
                        }),
                        tableblproj
                        
                        ]


        }]



                });








            Packer.toBlob(doc).then(blob => {

        saveAs(blob, "Suivi_fournisseur.docx");

            });

        },


        fetchListArts(row_bn)
        {
                    this.isLoading=true;
                    this.$http.get('/detailbonLivraison/'+row_bn.id)
                    .then((result) => {
                                this.ArticlesList = result.data.data
                                this.isLoading=false;


                               // this.curentBN=row_bn.nom + " ( "+row_bn.date+" )"
                                this.$root.$emit('bv::show::modal', 'detailModal')

                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });


        },
        fetchListArtsRetour(row_bn)
        {
                    this.isLoading=true;
                    this.$http.get('/detailAvoirFour/'+row_bn.id)
                    .then((result) => {
                                this.ArticlesList = result.data.data
                                this.isLoading=false;


                                //this.curentBN=row_bn.nom + " ( "+row_bn.date+" )"
                                this.$root.$emit('bv::show::modal', 'detailModal')

                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });


        },
        click_regler_situation()
        {
                    this.$confirm("Voulez-vous vraiment régler la situation courante de ce fournisseur ??")
                        .then(() => {

                                                //regler la situation
                                                this.isLoading=true;
                                                this.$http.get('/situtionReglFourn/'+this.selectedFournis.id)
                                                .then((result) => {

                                                console.log(result.data)
                                                this.flashMessage.show({
                                                status: 'success',
                                                title: 'Données modifiées',
                                                message: 'Les données sont modifiées correctement'
                                                })

                                                //recharger les données:
                                                this.click_RechrchFourns(0)

                                                })
                                                .catch(error=> {
                                                            console.log(error)
                                                            this.isLoading=false;
                                                            this.flashMessage.show({
                                                            status: 'error',
                                                            title: 'Erreur de Chargement',
                                                            message: 'Impossible charger les données.. Actualisez et Réessayez'
                                                            })
                                                });

                        })
                        .catch(error=> {
                        console.log(error)

                    });
        },
        validerPaiement(item,val)
        {
                this.itemFormValid.id=item.id
                this.itemFormValid.etat=val
                this.itemFormValid.user=this.user_name
                this.itemFormValid.date=new Date().toJSON().slice(0,10).replace(/-/g,'-')


                if(this.d_is_tresor==false)
                {

                        this.$confirm("Voulez-vous vraiment modifier l'etat de ce paiement ??")
                        .then(() => {

                        this.itemFormValid.bank_compte_id=-1;

                        this.handleSubmitValidPayem(); //appliquer etat
                        })
                        .catch(error=> {
                        console.log(error)

                        });

                }
                else
                {
                    this.itemFormValid.bank_compte_id=''

                    this.$root.$emit('bv::show::modal', 'comptModal')
                }

        },
        handleSubmitValidPayem() {


                if (this.itemFormValid.bank_compte_id) 
                {
                                            this.isLoading=true;

                                this.$http.post('/validerPayemFourn',{
                                id:this.itemFormValid.id,
                                etat:this.itemFormValid.etat,
                                user:this.itemFormValid.user,
                                bank_compte_id:this.itemFormValid.bank_compte_id,
                                date:this.itemForm.date


                                    })
                                    .then(response => {

                                        this.isLoading=false;
                                        console.log(response.data)
                                        
                                        this.$bvModal.hide('banqModal')

                                        let index = this.itemslist.findIndex(item => item.id === this.itemFormValid.id)
                                        this.itemslist.splice(index, 1,response.data.data)

                                        this.$bvModal.hide('comptModal')

                                        //afficher message
                                        this.flashMessage.show({
                                        status: 'success',
                                        title: 'Données sauvegardées',
                                        message: 'Les données sont sauvegardées correctement '
                                    })


                                    })
                                    .catch(error=> {console.log(error)
                                    this.isLoading=false;


                                                    this.flashMessage.show({
                                    status: 'error',
                                    title: 'Erreur De Sauvegarde',
                                    message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                                    })
                                    });
                }
                else 
                {
                this.flashMessage.show({
                status: 'info',
                title: 'Sélectionnez un compte !!',
                })
                }

        },  
        fetchModesFourniss: function () {

                    //chager les fournisseurs:
                    this.isLoading=true;
                    this.$http.get('/fournissMinActif')
                    .then((result) => {
                                this.fournisseurs = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });

                    //chager les modes:
                    this.isLoading=true;
                    this.$http.get('/bankModes')
                    .then((result) => {
                                this.modes = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });

                    //chager les modes:
                    this.isLoading=true;
                    this.$http.get('/bankComptes')
                    .then((result) => {
                                this.comptes = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });

        },
        handleSubmit() {

            
                //USER HANDLE
                this.itemForm.user= this.user_name

                if(this.itemForm.etat==0)
                {
                    this.itemForm.bank_compte_id=0
                    
                }
                else
                {
                    if(this.d_is_tresor==false)
                    {
                        this.itemForm.bank_compte_id=-1
                    }
                }

                
                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {


                            if(this.ModalEdit)
                            {
                                this.banqueEdit()
                            }
                            else
                            {

                                this.itemslisttore()
                            }   

                }

        },  
        itemslisttore(){
                        this.isLoading=true;

            this.$http.post('/paiementFours',{
            date:this.itemForm.date,
            montant:this.itemForm.montant,
            mode_oper:this.itemForm.mode_oper,
            infos_oper:this.itemForm.infos_oper,
            date_ech:this.itemForm.date_ech,
            description:this.itemForm.description,
            etat:this.itemForm.etat,
            regler:this.itemForm.regler,
            user:this.itemForm.user,
            fournisseur_id:this.itemForm.fournisseur_id,
            bank_compte_id:this.itemForm.bank_compte_id

                })
                .then(response => {

                    this.isLoading=false;
                    this.itemslist.unshift(response.data.data);
                    this.$bvModal.hide('banqModal')

                    //afficher message
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données sauvegardées',
                    message: 'Les données sont sauvegardées correctement '
                })


                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Sauvegarde',
                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                })
                });

        },

        add_Paiem_click(button) {




            this.itemForm.date=new Date().toJSON().slice(0,10).replace(/-/g,'-'),
            this.itemForm.montant=''
            this.itemForm.mode_oper=''
            this.itemForm.infos_oper=''

            let nbr = Number(this.selectedFournis.nrb_jour)
            //calculer le deles
                if(nbr > 0)
                {
            this.itemForm.date_ech= new Date(this.itemForm.date),
            this.itemForm.date_ech.setDate(this.itemForm.date_ech.getDate() + nbr);
                }
                else
                {
                    this.itemForm.date_ech=new Date().toJSON().slice(0,10).replace(/-/g,'-')
                }


            this.itemForm.description="Paie.Personnalisé: "+this.selectedFournis.nom
            this.itemForm.etat=0
            this.itemForm.regler=0
            this.itemForm.fournisseur_id = this.selectedFournis.id




            this.ModalEdit=false;
            this.ModalType='Ajouter'

            this.$root.$emit('bv::show::modal', 'banqModal', button)
        },
        edit_click(item, index, button) {

                    this.$confirm("Voulez-vous vraiment modifier cet élément ??")
                    .then(() => {

                this.itemForm.id=item.id
                this.itemForm.date=item.date
                this.itemForm.montant=item.montant
                this.itemForm.mode_oper=item.mode_oper
                this.itemForm.infos_oper=item.infos_oper
                this.itemForm.date_ech=item.date_ech
                this.itemForm.description=item.description
                this.itemForm.etat=item.etat
                this.itemForm.regler=item.regler
                this.itemForm.user=item.user
                this.itemForm.fournisseur_id=item.fournisseur_id





                    this.ModalEdit=true;
                    this.ModalType='Modifier'

                    this.$root.$emit('bv::show::modal', 'banqModal', button)
                    })
                    .catch(error=> {
                    console.log(error)

                    });


        },
        banqueEdit(){

                this.isLoading=true;

                this.$http.patch('/paiementFours/'+this.itemForm.id,{
                date:this.itemForm.date,
                montant:this.itemForm.montant,
                mode_oper:this.itemForm.mode_oper,
                infos_oper:this.itemForm.infos_oper,
                date_ech:this.itemForm.date_ech,
                description:this.itemForm.description,
                etat:this.itemForm.etat,
                regler:this.itemForm.regler,
                user:this.itemForm.user,
                fournisseur_id:this.itemForm.fournisseur_id

                    })
                    .then(response => {

                        this.isLoading=false;
                        console.log(response.data)
                        this.$bvModal.hide('banqModal')

                        let index = this.itemslist.findIndex(item => item.id === this.itemForm.id)
                        this.itemslist.splice(index, 1,response.data.data)
                        
                        this.flashMessage.show({
                        status: 'success',
                        title: 'Données modifiées',
                        message: 'Les données sont modifiées correctement'
                        })


                        })
                        .catch(error=> {console.log(error)
                        this.isLoading=false;


                        this.flashMessage.show({
                        status: 'error',
                        title: 'Erreur De Modification',
                        message: 'Impossible  de modifier les données .. Réessayez !!'
                    })
                    });

            },
        del_click(item) {

                    this.$confirm("Voulez-vous vraiment supprimer cet élément ??")
                    .then(() => {
                    this.itemForm.id=item.id

                                        this.PaiementDell()
                                    })
                                    .catch(error=> {
                                    console.log(error)

                                        });




        },
        PaiementDell(){

                this.isLoading=true;

                this.$http.delete('/paiementFours/'+this.itemForm.id)
                    .then(response => {
                    console.log(response.data)
                        this.isLoading=false;



                        
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données Supprimées',
                    message: 'Les données sont supprimées correctement'
                    })


                        let index = this.itemslist.findIndex(item => item.id === this.itemForm.id)
                        this.itemslist.splice(index, 1)




                        })
                    .catch(error=> {console.log(error)
                    this.isLoading=false;


                    this.flashMessage.show({
                    status: 'error',
                    title: 'Erreur De Suppression',
                    message: 'Impossible  de supprimer les données .. Réessayez !!'
                    })
                    });

        },

        click_RechrchFourns(reg)
        {
                if(this.selectedFournis.id)
                {
                                if(reg==0)
                                {
                                this.situCourent=true
                                }
                                else
                                {
                                this.situCourent=false
                                }
                                            
                                this.fetchListPaiem(reg)
                                this.fetchListLivraison(reg)
                                this.fetchListAvoir(reg)

                                this.fetchListLivrProjet(reg)
               
                }
                else
                {
                                this.flashMessage.show({
                                status: 'info',
                                title: 'Sélectionnez un fournisseur !!',
                                })
                }

        },

//paiements :
        fetchListPaiem: function (reg) {

                    //chager les depots:
                    this.isLoading=true;
                    this.$http.get('/paiementsFourReglers/'+this.selectedFournis.id+'/'+reg)
                    .then((result) => {

                                
                                this.itemslist = result.data.data
                                this.isLoading=false;


                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });


        },

//livraison coins


        fetchListLivrProjet: function (reg) {
                    this.isLoading=true;
                    this.$http.get('/projetLivraisRegler/'+this.selectedFournis.id+'/'+reg)
                    .then((result) => {
                                this.itemslistLivrProjet = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },



        fetchListLivraison: function (reg) {
                    this.isLoading=true;
                    this.$http.get('/livraisFourRegler/'+this.selectedFournis.id+'/'+reg)
                    .then((result) => {
                                this.itemslistLivraison = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },

        payeLivrais_click(item, index, button) {

                    this.$confirm("Voulez-vous créer un paiement pour ce bon ??")
                    .then(() => {



            this.itemForm.date=new Date().toJSON().slice(0,10).replace(/-/g,'-'),
            this.itemForm.montant=item.total_ttc
            this.itemForm.mode_oper=''
            this.itemForm.infos_oper=''

            this.itemForm.description=item.nom
            this.itemForm.etat=1
            this.itemForm.regler=0
            this.itemForm.fournisseur_id = this.selectedFournis.id



            let nbr = Number(this.selectedFournis.nrb_jour)
            //calculer le deles
                if(nbr > 0)
                {
                    this.itemForm.date= new Date(item.date);
                    this.itemForm.date_ech= new Date(item.date);
                    this.itemForm.date_ech.setDate(this.itemForm.date_ech.getDate() + nbr);
                }
                else
                {
                    this.itemForm.date= new Date(item.date);
                    this.itemForm.date_ech= new Date(item.date);
                }



            this.ModalEdit=false;
            this.ModalType='Ajouter'


                    this.$root.$emit('bv::show::modal', 'banqModal', button)
                    })
                    .catch(error=> {
                    console.log(error)

                    });


        },
  //Avoirs
          fetchListAvoir: function (reg) {
                    this.isLoading=true;
                    this.$http.get('/avoirFourRegler/'+this.selectedFournis.id+'/'+reg)
                    .then((result) => {
                                this.itemslistAvoir = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },
        payeAvoir_click(item, index, button) {

                    this.$confirm("Voulez-vous créer un paiement pour ce bon ??")
                    .then(() => {



            this.itemForm.date=new Date().toJSON().slice(0,10).replace(/-/g,'-'),
            this.itemForm.montant=item.total_ttc*-1
            this.itemForm.mode_oper=''
            this.itemForm.infos_oper=''
            this.itemForm.date_ech=new Date().toJSON().slice(0,10).replace(/-/g,'-'),
            this.itemForm.description=item.nom
            this.itemForm.etat=1
            this.itemForm.regler=0
            this.itemForm.fournisseur_id = this.selectedFournis.id


            this.ModalEdit=false;
            this.ModalType='Ajouter'


                    this.$root.$emit('bv::show::modal', 'banqModal', button)
                    })
                    .catch(error=> {
                    console.log(error)

                    });


        },
      rowClass(item, type) {
        if (!item || type !== 'row') return
        if (item.etat === 1) return 'table-success'
      }
},
components: {
              Loading      
}


}
</script>

<style>
.Pgheader {
  background-color: blue;
  opacity: 0.3;
}
.BgSearch {
  background-color:#BEE5EB;
  opacity: 0.9;
}
.var_color{
  color: #926e99;
}
</style>